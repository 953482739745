import React, { useEffect } from 'react';
import { TweenMax, Sine } from 'gsap';

import Svg from 'react-inlinesvg';
import logo from '../images/svgs/logo.svg';

import styles from '../scss/components/header.module.scss';

const Header = ({ white }) => {
  useEffect(() => {
    TweenMax.to('.header', 0.2, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 0.2,
    });
  }, []);

  return (
    <div className={`${styles.header} header`}>
      <Svg src={logo} className={`${styles.header__logo} ${white === "true" ? `${styles.header__logoWhite}` : ''}`} />
    </div>
  );
};

export default Header;
