import React from 'react';
import Helmet from 'react-helmet';
import uuid from 'uuid/v1';

const googleAnalytics = (id, pagePath) => [
  <script key={uuid()}>
    {`
      (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
              (i[r].q = i[r].q || []).push(arguments)
          }, i[r].l = 1 * new Date(); a = s.createElement(o),
              m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
      ga('create', ${id}, {'cookie_flags': 'max-age=7200;secure;samesite=none'});
      ga('send', 'pageview');
    `}
  </script>,
  <script async src={`https://www.googletagmanager.com/gtag/js?id=${id}`} key={uuid()} />,
  <script key={uuid()}>
    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${id}', {
        'anonymize_ip' : true,
        'page_path': "${pagePath}",
        'cookie_flags': 'max-age=7200;secure;samesite=none'
      });
    `}
  </script>,
];

function Analytics({ pageUrl }) {
  return <Helmet>{googleAnalytics(process.env.GATSBY_GA_ID, pageUrl)}</Helmet>;
}

export default Analytics;
