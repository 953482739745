import React from 'react';
import styles from '../scss/components/product.module.scss';

const Product = product => {
  const productDetails = product.product;

  return (
    <div className={styles.product}>
      {productDetails && (
        <div className="flex-center">
          <div className={`${styles.product__details} flex-center`}>
            <img src={require(`../images${productDetails.image.url}`)} className={styles.product__image} alt=""/>
            <p className={styles.product__name}>{productDetails.name}</p>
            <p>{productDetails.price}</p>
            </div>
          <a href={productDetails.url} data-event-label={`Shop Now - ${productDetails.name}`} target="_blank" className="button">Shop now</a>
        </div>
      )}
    </div>
  );
};

export default Product;
