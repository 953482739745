import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby'
import { TweenMax, Sine } from 'gsap';
import sendGaEvent from '../utils/sendGaEvent';
import Analytics from '../components/analytics';
import Layout from '../components/layout';
import Product from '../components/product';
import Header from '../components/header';
import cms from '../cms.json';
import header from '../images/payoff.jpg';

import styles from '../scss/components/payoff.module.scss';

const Payoff = () => {
  const isBrowser = typeof window !== 'undefined';
  const products = cms.payoff.products;
  const [product, setProduct] = useState(null);

  const chosenProduct = () => {
    const answerOne = localStorage.getItem('answerOne');
    const answerTwo = localStorage.getItem('answerTwo');
    
    if (answerOne === '2' && answerTwo === '2') {
      setProduct(products[0]);
    } else if (answerOne === '1' && answerTwo === '2') {
      setProduct(products[1]);
    } else if (answerOne === '3' && answerTwo === '1') {
      setProduct(products[2]);
    } else if (answerOne === '2' && answerTwo === '1') {
      setProduct(products[3]);
    } else if (answerOne === '3' && answerTwo === '2') {
      setProduct(products[4]);
    } else if (answerOne === '2' && answerTwo === '3') {
      setProduct(products[5]);
    } else if (answerOne === '1' && answerTwo === '1') {
      setProduct(products[6]);
    } else if (answerOne === '1' && answerTwo === '3') {
      setProduct(products[7]);
    } else if (answerOne === '3' && answerTwo === '3') {
      setProduct(products[8]);
    }
  };

  const startAgain = () => {
    navigate('/');
    sendGaEvent('start again button', '/payoff')
  };

  useEffect(() => {
    if (isBrowser) {
      chosenProduct();
    }
  }, []);

  useEffect(() => {
    TweenMax.to('.payoff', 0.5, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 0.5,
    });
  }, []);

  return (
    <Layout className={`${styles.payoff} payoff`}>
      <Analytics pageUrl="/payoff" />
      <Header className={styles.payoff__header} white="true" />
      <div className={styles.payoff__wrapper}>
        <img className={styles.payoff__image} src={header} alt="" />
        <div className={styles.payoff__lockup}>
          <button className="cta" onClick={startAgain}>{cms.payoff.cta}</button>
          <h1>{cms.payoff.title}</h1>
        </div>
      </div>
      <Product product={product} />
    </Layout>
  );
}

export default Payoff;
