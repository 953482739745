import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '../scss/styles.scss';

import closestParent from '../utils/closestParent';
import sendGaEvent from '../utils/sendGaEvent';

const Layout = ({ children }) => {
  // check if clicked element has 'data-event-label' and send event to GA
  const checkForGa = event => {
    const element = closestParent(event.target, '[data-event-label]', true);
    if (element) {
      const eventLabel = element.getAttribute('data-event-label');
      sendGaEvent(eventLabel);
    }
  };

  useEffect(() => {
    document.addEventListener('click', checkForGa, false);

    return () => {
      document.removeEventListener('click', checkForGa, false);
    };
  }, []);
  return (
    <div className="layout">
      <main>{children}</main>
    </div>
  )
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  // cms: PropTypes.objectOf(PropTypes.any).isRequired,
};
